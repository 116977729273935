/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    function menuScroll() {
        var WW = $(window).width();
        var HH = $('header.banner').height();
        $('header.banner .titoli').css({ 'height': HH });
        $('header.banner .titoli .round-menu.menu').css({ 'top': (HH / 2) - 25 });
        $('.round-menu.portfolioB').css({ 'top': (HH / 2) - 25 });
        $('.nav-primary ul').css({ 'top': (HH - $('.nav-primary ul').height() - 60) / 2 });
        if (WW <= 768) {
            $('header.banner .titoli .round-menu.menu').addClass('mobile')
            $('header.banner .cont-nav .nav-primary').addClass('mobile')
            $('body').addClass('mobile');
        } else {
            $('header.banner .titoli .round-menu.menu').removeClass('mobile')
            $('header.banner .cont-nav .nav-primary').removeClass('mobile')
            $('body').removeClass('mobile');
        }
    }

    function VaiSu() {
        window.scrollTo(0, 0);
    }

    function displayWait() {
        $('.displayajax').addClass('eccolo');
    }

    function ciclami() {
        if ($('.fader div').hasClass('attivo')) {
            var $attivo = $('.fader .attivo');
        } else {
            var $attivo = $('.fader div:first').addClass('attivo');
        }
        var $next = ($('.fader .attivo').next().length > 0) ? $('.fader .attivo').next() : $('.fader div:first');
        $next.css('z-index', 2); //move the next image up the pile
        $attivo.delay(10000).fadeOut(2000, function() { //fade out the top image
            $attivo.css('z-index', 1).show().removeClass('attivo'); //reset the z-index and unhide the image
            $next.css('z-index', 3).addClass('attivo');
            setTimeout(ciclami, 1000) //make the next image the top one
        });
    };



    function Barre() {
        $('.soft #barre li#barra').each(function() {
            var altezz = $(this).attr('data-per');
            var AltOk = (altezz * 300) / 100;
            var colOk = altezz / 100;
            $(this).find('div').css({ 'height': AltOk, 'opacity': colOk });
        });
    }

    function Palle() {
        $('#titoli li').each(function() {
            var topp = ((($('.circle').height() * $(this).attr('data-top')) / 100) - $(this).height() / 2) + 'px';
            var leftt = ((($('.circle').width() * $(this).attr('data-left')) / 100) - $(this).width() / 2) + 'px';
            $(this).css({ 'top': topp, 'left': leftt });
        });
        $('#cerchi li').each(function() {
            var toppc = (($('.circle').height() * $(this).attr('data-top')) / 100) + 'px';
            var lefttc = (($('.circle').width() * $(this).attr('data-left')) / 100) + 'px';
            $(this).css({ 'top': toppc, 'left': lefttc });
        });
    }



    $(document).on('scroll', function() {
        if ($(document).scrollTop() >= ($('.page-template-template-about .wrap').height() - 300)) {
            $('#cerchi, #titoli').addClass('attivi');
            Barre();
        }

        if ($(window).scrollTop() >= ($('body.single-post article').outerHeight()) - $(window).height() + 250) {
            $('.social_addon').addClass('active');
        }

    });



    $('.tito a').on('click', function(e) {
        e.preventDefault();
    });



    function ModaleRight() {
        $('.modalePortfolio').css({ 'right': '-' + $(window).width() });
    }




    function AllSlide() {
        if ($(window).width() >= 768) {
            $('section.slides').height($(window).height());
            $('section.slides').find('.slideCont').css({ 'padding-top': (($(window).height() - $(this).find('.slideCont').height()) / 4) + 34 })
        } else {
            $('section.slides').css({ 'height': 'auto' });
            $('section.slides').find('.slideCont').css({ 'padding-top': (($(window).height() - $(this).find('.slideCont').height()) / 4) + 26 })
        }
    }

    $('.navbar-toggle').on('click', function() {
        $(this).toggleClass('click');
        $('.cont-nav').toggleClass('open');
    });




    $('.fader').fadeIn('slow').delay(10000, function() {
        ciclami();
    });

    function loghiAlCentro() {
        $('img.logo').css({ 'margin-top': ($('img.splash').height() - $('img.logo').height()) / 2 });
    }

    $('.portfolioCont').imagesLoaded(function() {
        loghiAlCentro();
    });

    function PartePort() {
        $('.portfolioSing.mod').on('click', function() {
            $(this).find('.modalePortfolio').addClass('active');
            $('.portfolioB').addClass('attivo');
            $('.round-menu.menu').addClass('att');
        });

        $('.portfolioB').on('click', function() {
            $('.modalePortfolio').removeClass('active');
            $(this).removeClass('attivo');
            $('.round-menu.menu').removeClass('att');
        });
    }



    function SliderHome() {
        $('.owl-carousel.slideCont').owlCarousel({
            items: 1,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            navSpeed: 300,
            mouseDrag: true,
            touchDrag: true,
            nav: true,
            navText: ["<p class='fa fa-chevron-left></p>'", "<p class='fa fa-chevron-right></p>"]
        });
        $('.owl-prev').addClass('fa fa-angle-left');
        $('.owl-next').addClass('fa fa-angle-right');
    }


    $('.owl-carousel.galleryCont').owlCarousel({
        items: 1,
        loop: true,
        autoplay: false,
        autoplayTimeout: 5000,
        navSpeed: 300,
        mouseDrag: true,
        touchDrag: true,
        nav: false
    });

    var owl = $('.owl-carousel.galleryCont');
    $('.gallery .owl-next').click(function() {
        owl.trigger('next.owl.carousel');
    });
    $('.gallery .owl-prev').click(function() {
        owl.trigger('prev.owl.carousel');
    });





    // ajax
    // CONTROLLER
    function cliccaAndStart() {
        cliccaAndStartMenu();
        cliccaAndStartRest();
    }

    function cliccaAndStartMenu() {
        $('.nav-primary #menu-menu-1 li a, .nav-primary #menu-menu-1-en li a').each(function() {
            $(this).on('click', function(e) {
                url = $(this).attr('href');
                e.preventDefault();
                FireAnimazioni('home');
                FireAjax(url);
            })
        });
    }

    function cliccaAndStartRest() {
        $('.mainContent .pages a, .relazioniSing a, .singlePost a').each(function() {
            $(this).on('click', function(e) {
                url = $(this).attr('href');
                e.preventDefault();
                FireAnimazioni();
                FireAjax(url);
            })
        });
    }

    // MODEL
    function FireAnimazioni(arg) {
        if (arg == 'home') {
            closePanel()
        }
        setTimeout(function() {
            displayWait()
        }, 100);
        NascondiCont();
        setTimeout(function() {
            VaiSu();
        }, 550);
    };

    function FireAjax(qui) {
        $.ajax({
            url: qui,
            type: 'post',
            data: {
                action: 'ajax_pagination'
            },
            success: function(code) {
                html = $(code);
                var matchesBody = code.match(/<body.*class=["']([^"']*)["'].*>/);
                $('body').removeClass().addClass(matchesBody[1]);
                $wrap = html.filter('.ajaxCall').html();
                $('.ajaxCall').empty();
                $('.ajaxCall').html($wrap);
                window.history.pushState('Bonellicio.us', 'Bonellicio.us', qui);
                $('body').imagesLoaded(function() {
                    tornaComePrimaCnt();
                    removeWait();
                    initialize();
                })
            }
        });
    }


    // VIEW
    function displayWait() {
        $('#displayajax').addClass('eccolo');
    }

    function removeWait() {
        $('#displayajax').removeClass('eccolo');
    }

    function closePanel() {
        $('#rollingBtn').removeClass('click');
        $('#cont-nav').removeClass('open');
    }

    function NascondiCont() {
        $('.ajaxCall').addClass('disappear');
    }

    function VaiSu() {
        window.scrollTo(0, 0);
    };

    function tornaComePrimaCnt() {
        $('.ajaxCall').removeClass('disappear');
        $('.displayajax').removeClass('eccolo');
        $('.ajaxCall').removeClass('onload');
    }

    function initialize() {
        cliccaAndStartRest();
        Palle();
        AllSlide();
        SliderHome();
        PartePort();

        $('.owl-carousel.galleryCont').owlCarousel({
            items: 1,
            loop: true,
            autoplay: false,
            autoplayTimeout: 5000,
            navSpeed: 300,
            mouseDrag: true,
            touchDrag: true,
            nav: false
        });

        var owl = $('.owl-carousel.galleryCont');
        $('.gallery .owl-next').click(function() {
            owl.trigger('next.owl.carousel');
        });
        $('.gallery .owl-prev').click(function() {
            owl.trigger('prev.owl.carousel');
        });


        loghiAlCentro();
        Barre();
    }



    menuScroll();
    ModaleRight();
    AllSlide();
    Palle();
    cliccaAndStart();
    SliderHome();
    PartePort();

    $(window).on('resize', function() {
        menuScroll();
        AllSlide();
        loghiAlCentro();
        ModaleRight();
        Palle();
        Barre();
        PartePort();
    });



    $('.curtain').fadeOut();


})(jQuery); // Fully reference jQuery after this point.